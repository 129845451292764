import { Box, Typography } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { EmployeeAccount, Plan, ServiceSector } from "../models";
import { useData } from "../utils/useData";
import { AcceptedServicesList } from "./AcceptedServicesList";
import { AccountCard } from "./AccountCard";
import { SectionHeader } from "./SectionHeader";

export const AccountsList: FC<{ accounts: EmployeeAccount[] }> = ({
  accounts
}) => {
  const { plans, serviceSectors } = useData();
  const [selectedPlanIndex, setSelectedPlanIndex] = useState(0);
  const [selectedPlanServiceSectors, setSelectedPlanServiceSectors] = useState<
    ServiceSector[] | undefined
  >();

  const getPlanServiceSectors = (plan: Plan): ServiceSector[] | undefined => {
    const planServiceSectorIds = plan?.controls?.serviceSectors.map((ss) => ss.id) || [];
    return serviceSectors.data?.items.filter((ss) =>
      planServiceSectorIds.includes(ss.id)
    );
  };

  const handlePlanSelect = () => {
    if (!accounts[selectedPlanIndex]) return;
    let selectedPlan = plans.data?.items.find(
      (p) => p.id === accounts[selectedPlanIndex].planId
    );
    if (!selectedPlan) return;
    setSelectedPlanServiceSectors(getPlanServiceSectors(selectedPlan));
  };

  useEffect(() => {
    if (!accounts.length || !plans.data) return;
    handlePlanSelect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPlanIndex, plans.data]);

  if (plans.isLoading || !plans.data) {
    return <>Loading...</>;
  }

  return (
    <>
      <Box>
        {accounts.map((a, i) => {
          const plan = plans.data!.items.find((x) => x.id === a.planId);
          return (
            <Box key={i} onClick={() => setSelectedPlanIndex(i)} my={2}>
              <AccountCard
                account={a}
                plan={plan!}
                isSelected={selectedPlanIndex === i}
              />
            </Box>
          );
        })}
      </Box>
      <Box mt={3}>
        <SectionHeader text="Use with the following" />
        <Typography variant="body2">
          Your card is accepted at all businesses that take Mastercard/debit
          cards and offer the following services:
        </Typography>
        <AcceptedServicesList serviceSectors={selectedPlanServiceSectors} />
      </Box>
    </>
  );
};
